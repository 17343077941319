import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 className="px-2 text-center">Trailguide solutions</h1>
    <h3 className="text-center text-lg">
  An overview of the products and solutions we offer
    </h3>
    <br />
    <Image src="/news/tg_2023_spring_edition.jpg" mdxType="Image" />
    <br />
    <h4>{`Trailguide.net`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  <Link to="https://trailguide.net" mdxType="Link">Trailguide.net</Link> is a freemium web app
  to share, find, and navigate mountain biking trails. The content is
  crowd-sourced by outdoor enthusiasts. The app offers interactive maps, height
  profiles, GPS navigation, tour descriptions, images, videos, reviews,
  condition reports, personalized "playlists", and more. Users can subscribe to
  Trailguide Premium, giving them access to extra features. Read more{" "}
  <Link to="/products/app" mdxType="Link">here</Link> and{" "}
  <Link to="/news/trailguide_2023_spring_edition" mdxType="Link">here</Link>.
    </div>
    <br />
    <h4>{`Web integration solutions`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We offer solutions to integrate content from the Trailguide database on
  websites using a tiny piece of code. The plugin module is highly flexible and
  customizable and can be configured to show exactly the data needed by the
  customer.
    </div>
    <Image src="/website/desktop-integration-trysil.jpg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The customer has one source of truth and does not need to update and keep
  fresh their content on multiple platforms. It will automatically be updated
  and propagated on all digital platforms. For more information see{" "}
  <Link to="/products/plugin" mdxType="Link">here</Link>.
    </div>
    <br />
    <h4>{`Tour collections and QR codes`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Customers can set up their own collections of trails, tours, and activities,
  either by selecting from the crowd sourced content or by adding their own. For
  each of these collections a QR code is generated which can be referred to by
  staff and scanned by guests to access the content, digital maps, and GPS
  navigation.
    </div>
    <Image src="/website/trailguide-qr-display.jpg" mdxType="Image" />
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Within a couple of seconds of scanning such a QR code, the guest will have
  access to all relevant tour content, digital maps, and GPS navigation. There
  is no need to download and install an app, no need for any registration, and
  no need to search for the specific information you were looking for. It is all
  there in an instant.
    </div>
    <br />
    <h4>{`White-label tour apps`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  We offer white-label custom tour apps for destination customers where the
  customer has total control over the content presented to guests and visitors.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The app is customizable with specific content, functionality and visual
  appearance and is accessible to guesta and visitors through a QR code or a
  link. It can easily be installed as a normal app by adding it to the home
  screen of the device.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  It takes less than 5 minutes to create and set up a white-label app for a
  customer. Examples of custom apps can be found at{" "}
  <Link to="https://trysil.our.guide" mdxType="Link">trysil.our.guide</Link> and{" "}
  <Link to="https://hallingdalrides.our.guide" mdxType="Link">hallingdalrides.our.guide</Link>.
    </div>
    <br />
    <h4>{`Custom tour apps`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Building on our tech platform we can set up custom tour apps. Setting up a
  custom tour app is just a matter of hours and mostly involves setting up a
  configuration file on top of the trailguide platform and a new custom domain.
    </div>
    <br />
    <h4>{`Trailguide ads`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Tourist regions, destinations, resorts, hotels, campsites, B&Bs, lodges,
  shops, cafés, guides, and other businesses tending to tourists and visitors
  can pay to have a presence on the map of the Trailguide app. Read more{" "}
  <Link to="/products/presence" mdxType="Link">here</Link> and{" "}
  <Link to="/products/landingpage" mdxType="Link">here</Link>.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      